.card-body {
  background-color: transparent !important;
}
.bg-green {
  background-color: $votes-background !important;
}

.drink-logo {
  width: 50px;
}

.votes-title {
  color: $votes-title !important;
  font-size: 13px !important;
}
.votes-description {
  color: $alquimiaWhite !important;
  font-weight: bold;
  // font-size: 40px !important;
  font-size: 20px !important;

}

.votes-aquimia-star {
  // width: 250px;
  text-align: center;
  img {
    width: 3.5em;
  }
}

svg.star-svg {
  width: 40px !important;
  height: 40px !important;
}

.branch-images-two-columns {
  // width: 200px;
  width: auto;
  height: 130px;
  border: 3px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  img {
    width: 110px;
    padding-top: 12px;
  }
}
