.container-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
  .avatar-upload {
    position: absolute;
    right: 20px;
    z-index: 1;
    top: 15px;
    input {
      display: none;
      + label {
        width: 34px;
        height: 34px;
        i {
          padding-top: 20%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  .avatar-preview {
    // width: 192px;
    // height: 192px;
    width: auto;
    height: 192px;
    position: relative;
    border-radius: 100%;
    border: 6px solid $light-white;
    box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.1);
    object-fit: cover;
    > img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.cropper-component {
  height: 400px;
  width: '100%';
  overflow-y: hidden;
  overflow-x: hidden;
}

.circle-btn {
  border-radius: 100% !important;
}

@media(max-width:1260px){
  .container-upload {
    .avatar-preview {
      width: 100%;
      height: 160px;
    }
  }
}

@media(max-width:1130px){
  .container-upload {
    .avatar-preview {
      width: auto;
      height: 192px;
    }
  }
}

@media(max-width:250px){
  .container-upload {
    .avatar-preview {
      width: auto;
      height: 140px;
    }
  }
}
