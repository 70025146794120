@import './Settings.scss';

.vote-item-title,
.table-header-title,
.average-number {
  color: $alquimiaPurple;
  font-weight: bold;
}

.bg-whitegray,
.table-header-properties,
.table-header-title {
  background-color: $alquimiaWhite !important;
}

.table-header-title {
  border: 5px solid $alquimiaRed;
  padding: 10px;
  border-radius: unset !important;
  -webkit-border-radius: unset !important;
  -moz-border-radius: unset !important;
  -ms-border-radius: unset !important;
  -o-border-radius: unset !important;
}

.alquimia-table tr th:nth-child(1) .table-header-title,
.alquimia-table tr th:nth-child(2) .table-header-title,
.alquimia-table tr th:nth-child(3) .table-header-title, 
.alquimia-table tr th:nth-child(4) .table-header-title {
  border-color: transparent;
  font-weight: bold;
  font-size: 1.7em;
  border: 0px;
}

.average-number {
  font-size: 1.4em;
}
