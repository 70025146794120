@import './Settings.scss';
@import 'cropperjs/dist/cropper.css';
@import './ModalCropper.scss';
@import './ModalsStandarStyle.scss';
@import './Login.scss';
@import './RoutesContainer.scss';
@import './Inicio.scss';
@import './ExampleComponent.scss';
@import './DrinksVotes.scss';
@import './Votes.scss';
@import './RecordsReport.scss';


html,
body {
  color: $text-color;
  font-family: $font;
}

body:has(.drink-vote-box) {
  background-color: $alquimiaGreen;
}

.textCapitalize {
  text-transform: capitalize;
}

.main-sidebar {
  background-color: $sidebar-bg-color !important;
}

//GLOBAL BUTTONS TO SET ACTIONS
.buttonFirstAction {
  background-color: $light-white !important;
  color: $btn-accept-hover !important;
  font-size: 14px !important;
  padding: 7px 20px 7px 20px !important;
  margin-top: 0px !important;
  width: 100% !important;
  border: 1px solid $btn-accept-hover;
  margin-top: 10px !important;
}
.buttonFirstAction:hover {
  background-color: #c1c1c1 !important;
}
.buttonSecondAction {
  background-color: $btn-accept-color !important;
  color: $btn-accept-txtcolor !important;
  font-size: 14px !important;
  padding: 7px 20px 7px 20px !important;
  margin-top: 0px !important;
  width: 100% !important;
  margin-top: 10px !important;
  border: 0px !important;
}
.buttonSecondAction:hover {
  background-color: $btn-accept-hover !important;
}
.buttonThirdAction {
  background-color: $alquimia-red-light !important;
  color: $light-white !important;
  font-size: 14px !important;
  padding: 7px 20px 7px 20px !important;
  margin-top: 0px !important;
  border: 0px !important;
}
.buttonThirdAction:hover {
  background-color: $alquimia-red-high !important;
}
.buttonFourthAction {
  background-color: $light-white !important;
  color: $gray-dark !important;
  font-size: 14px !important;
  padding: 7px 20px 7px 20px !important;
  margin-top: 0px !important;
  border: 1px solid gray !important;
}
.buttonFourthAction:hover {
  background-color: $light-white-hover !important;
}
/*GLOBALS CARDS*/
.cardTitle {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}
.cardContent {
  font-size: 1.25rem;
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active {
  background-color: $menu-active-color !important;
  color: $text-color !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.main-footer {
  a {
    font-weight: bold;
    color: $light-white;
  }
  background-color: $alquimia-red-light;
  color: $light-white;
}

.navbar-light {
  background-color: $alquimia-red-light;
}

.navbar-light .navbar-nav .nav-link {
  color: $alquimia-red-high !important;
  background-color: $alquimia-red-light;
}

.navbar .logos {
  display: flex;
  justify-content: center;
  width: 100%;
  img {
    width: 40px;
    height: 40px;
    margin-left: 48px;
  }
}

.header-title {
  font-weight: bold !important;
  font-family: 'Alquimia_Text';
  letter-spacing: 2px;
}

.recovery-box {
  font-family: 'Alquimia_Title';
  color: $alquimia-red-light !important;
  letter-spacing: 2px;
}

//Login
[class*='sidebar-dark'] .user-panel {
  border-bottom: 1px solid $gray-dark;
}

.sidebar-mini .layout-fixed {
  overflow: hidden;
  background: $danger !important;
}

//OVERRIDE COLORS PRINCIPAL WIDGETS
.sidebar-dark-primary,
.header-gray {
  background-color: $gray-dark;
}

.content-wrapper {
  background-color: $light-white;
}

.content-wrapper:has(.dashboard) {
  background-color: $alquimia-red-light;
}
#sidebarMain:has(.dashboard) {
  background-color: $alquimia-red-light;
}

.bg-info {
  background-color: $primary;
  color: $gray-dark;
}

.btn-secondary,
secondary {
  background-color: $secondary;
}

.sidebar {
  background-color: #490005;
  font-family: 'Alquimia_Title';
  letter-spacing: 2px;
  .nav-sidebar .nav-item > .nav-link {
    color: $light-white;
    font-size: 14px;
  }
}

[class*='sidebar-dark-'] .sidebar a {
  // color: $text-color;
  color: $light-white;
  font-size: 16px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: $primary;
  color: $light-white;
}

.direct-chat-primary .right > .direct-chat-text {
  background-color: $info;
  border-color: $info;
  color: $light-white;
}

.small-box-footer {
  color: $secondary !important;
}

.breadcrumb {
  text-transform: uppercase;
}

//ContentHeader input date COLORADO START
.inputDateColorado {
  border: 1px solid $secondary;
  padding: 0px 12px 0px 12px;
}

.breadcrumb-item > a {
  color: $primary !important;
}

.card-header {
  h3 {
    font-size: 20px;
    color: $light-white !important;
  }
  background-color: $card-header;
}

.card-header > .card-title,
.header-title {
  color: $text-color;
  font-family: 'Alquimia_Text';
  letter-spacing: 2px;
}

.card-header .card-subTitle {
  color: #cfcfcf;
  // margin-top: 4px;
}

.header-title {
  font-size: 30px !important;
  font-weight: bold;
}

.card-body {
  background-color: $card-body;
  color: $secondary;
  font-family: 'Alquimia_Title';
  letter-spacing: 2px;
}

.card-body #headerFilters {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between;
}

.title-filter {
  color: $text-color;
  font-weight: normal !important;
  text-transform: none !important;
  cursor: pointer;
}

.layout-configs {
  height: 100%;
  .rdt_TableHead {
    font-weight: bold !important;
    font-size: 16px;
    color: $layout-table-content-color;
    text-transform: uppercase;
  }
  .rdt_TableBody {
    .rdt_TableRow {
      font-size: 14px;
      color: $layout-table-content-color;
    }
  }
  .rdt_Pagination {
    font-size: 14px;
    color: $layout-table-content-color;
    justify-content: center;
  }
}

.implement-password-view {
  -webkit-text-security: disc;
}
.implement-password-view-none {
  -webkit-text-security: none;
}

//BUTTONS
.btn {
  border-radius: 0%;
}

.btn-accept,
.btn-recovery {
  color: $btn-accept-txtcolor;
  font-size: 14px;
  background-color: $btn-accept-color;
  border-color: $btn-accept-border;
  box-shadow: none;
}

.btn-accept:hover {
  color: $btn-accept-txtcolor;
  background-color: $btn-accept-hover;
  border-color: $btn-accept-border;
}

.btn-accept:focus,
.btn-accept.focus {
  color: $btn-accept-txtcolor;
  background-color: $btn-accept-hover;
  border-color: $btn-accept-border;
  box-shadow: 0 0 0 0 rgba(16, 89, 167, 0.5);
}

.btn-accept.disabled,
.btn-accept:disabled {
  color: $btn-accept-txtcolor;
  background-color: $btn-accept-color;
  border-color: $btn-accept-border;
}

.btn-accept:not(:disabled):not(.disabled):active,
.btn-accept:not(:disabled):not(.disabled).active,
.show > .btn-accept.dropdown-toggle {
  color: $btn-accept-txtcolor;
  background-color: $btn-accept-color;
  border-color: $btn-accept-border;
}

.btn-accept:not(:disabled):not(.disabled):active:focus,
.btn-accept:not(:disabled):not(.disabled).active:focus,
.show > .btn-accept.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(16, 89, 167, 0.5);
}

.btn-info,
info {
  color: $btn-info-txtcolor;
  background-color: $info;
}

.btn-info:hover {
  color: $btn-info-txtcolor;
  background-color: $info-hover;
}

.btn-info:focus,
.btn-info.focus {
  color: $btn-info-txtcolor;
  background-color: $info-hover;
  border-color: $info-border;
  box-shadow: 0 0 0 0 rgb(63, 31, 206);
}

.btn-danger,
danger {
  background-color: $alquimia-red-high;
}

.btn-danger:hover {
  color: $btn-danger-txtcolor;
  background-color: $danger-hover;
}

.btn-danger:focus,
.btn-danger.focus {
  color: $btn-danger-txtcolor;
  background-color: $danger-hover;
  border-color: $danger-border;
  box-shadow: 0 0 0 0 rgb(87, 8, 8);
}

[class*='sidebar-dark-'] .nav-item-list > .nav-item > .nav-link:hover,
[class*='sidebar-dark-'] .nav-item-list > .nav-item > .nav-link:focus {
  background-color: $menu-hover-colorBG;
  color: $menu-hover-txtcolor;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: $menu-hover-colorBG;
  color: $menu-hover-txtcolor;
}

[class*='sidebar-dark-'] .nav-sidebar > .nav-item.menu-open > .nav-link,
[class*='sidebar-dark-'] .nav-sidebar > .nav-item:hover > .nav-link,
[class*='sidebar-dark-'] .nav-sidebar > .nav-item > .nav-link:focus {
  background-color: $menu-hover-colorBG;
  color: $menu-hover-txtcolor;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

[class*='sidebar-dark-'] .nav-item-list > .nav-item > .nav-link.active,
[class*='sidebar-dark-'] .nav-item-list > .nav-item > .nav-link.active:hover,
[class*='sidebar-dark-'] .nav-item-list > .nav-item > .nav-link.active:focus {
  background-color: $submenu-active-color;
  color: #fff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $checkbox-colorBG;
}

.toggle-up {
  transform: rotate(90deg);
}

.toggle-down {
  transform: rotate(0);
}

.buttons-recovery {
  display: flex;
  justify-content: flex-end;
}

.control-btn-box {
  display: flex;
  flex-direction: row-reverse;
  gap: 2%;
}

.control-btn-box-right {
  display: flex;
  gap: 2%;
}

.error {
  color: $danger;
}

.no-border {
  border: 0px !important;
}

.img-sw {
  width: 150px !important;
}

.fit-img {
  object-fit: cover;
}

//Permissions checkbox
.permissions-cardH {
  background-color: $sidebar-bg-color !important;
  h3 {
    color: $text-color !important;
  }
}

.card-wrapper {
  label {
    color: $text-color;
    font-size: 14px;
  }
  display: flex;
  flex-direction: row;
  @media (max-width: 670px) {
    display: block;
    flex-direction: column;
  }
}

.card-wrapper-checkbox {
  label {
    color: $text-color;
    font-size: 14px;
  }

  display: flex;
  flex-direction: row;

  @media (max-width: 1200px) {
    display: block;
    flex-direction: column;
  }
}

//Loading Layer
.container-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  background-color: $login-bg-color;
  padding: 0;
  margin: 0;
}
.section-loading {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: $light-white;
    margin-bottom: -25%;
    margin-top: 10%;
    font-size: 3em;
  }
}

//Contents Fade
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.content-fade-login {
  animation: fadeIn linear 0.7s;
  -webkit-animation: fadeIn linear 0.7s;
  -moz-animation: fadeIn linear 0.7s;
  -o-animation: fadeIn linear 0.7s;
  -ms-animation: fadeIn linear 0.7s;
}

.content-fade {
  animation: fadeIn linear 0.3s;
  -webkit-animation: fadeIn linear 0.3s;
  -moz-animation: fadeIn linear 0.3s;
  -o-animation: fadeIn linear 0.3s;
  -ms-animation: fadeIn linear 0.3s;
}

// 404 ERROR PAGE
.container-notfound {
  display: flex;
  flex-direction: column;
  background-color: $light-white;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

.title-notfound {
  font-size: 2em;
  font-weight: 500;
}

.subtitle-notfound {
  font-size: 1em;
  font-weight: 400;
}

//LOGOS SIDEBAR
.logo-switch {
  background-color: $alquimia-red-light;
}
.brand-image.logo-xl {
  display: inline;
  width: 160px;
  margin: 0px 30px 0px 30px;
}
[class*='sidebar-dark'] .brand-link {
  border-bottom: 1px solid $alquimia-red-high;
}

.brand-image.logo-xs {
  display: inline;
  margin-left: 0;
  width: 50px;
  height: 100px;
  margin-right: auto;
}

//SEARCH BAR LAYOUT TABLE
.position-searchInput {
  margin-left: 0px !important;
  margin-bottom: 2%;
}

#searchBar {
  background-image: url('../Assets/icons/search.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 98%;
}

.container-pic {
  max-width: 205px !important;

  .avatar-preview {
    width: 40px !important;
    height: 40px !important;
    border: 2px solid $light-white;
    box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.1);
    object-fit: cover !important;

    > img {
      width: 100% !important;
      height: 100% !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
    }
  }
}

.position-btnExport {
  margin: auto !important;
}

.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon {
  padding: 12px;
  margin-left: -3%;
  min-width: 40px;
}

.boxActive {
  background-color: $btn-accept-color;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  color: white;
}

.input-field {
  margin-left: -2.9% !important;
  font-size: 14px;
  width: 100%;
  padding: 10px;
  padding-left: 4% !important;
}

.platform-selector {
  select {
    color: $layout-table-content-color !important;
  }
}
.btn-more-actions {
  left: -90% !important;
}

.pointer {
  cursor: pointer;
}

.hide {
  display: none;
}

.detail-hover:hover {
  b {
    color: $primary;
  }
  p {
    background-color: $card-header;
  }
}

a {
  color: $primary;
}

.swal2-actions {
  gap: 20px;
}

@media (max-width: 500px) {
  #boxLogin {
    width: 90% !important;
  }
}

.header-text-details {
  font-size: 1rem;
  color: #707070;
  font-weight: bold;
  text-transform: uppercase;
}

.content-text-details {
  color: #707070;
  font-size: 1.25rem;
}

.box-separator {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 20px;
}

.box-separator-top {
  border-top: 1px solid #ebebeb;
  margin-top: 20px;
}

.cost-header {
  font-size: 20px;
  color: #707070;
}

.cost-text {
  font-size: 20px;
  color: #707070;
}

.process-btn {
  font-size: 16px;
  border: none;
  background-color: transparent;
  padding: 5px 10px;
  cursor: pointer;
}

.process-btn__red {
  background-color: #c3353f;
  color: #ffffff;

  &:hover {
    background-color: #921823;
    color: #ffffff;
  }
}

.process-btn__gray {
  background-color: #707070;
  color: #ffffff;

  &:hover {
    background-color: #4f4f4f;
    color: #ffffff;
  }
}

.product-detail-text {
  font-size: 14px;
  color: #707070;
}

.badge {
  color: $badge-text-color !important;
  border-radius: 100%;
  width: auto;
  min-width: 12%;
  min-height: 12%;
  padding: 4%;
}

.badge-color {
  margin-top: -3%;
  background-color: $badge-color !important;
}

.nav-link.active {
  span {
    color: $light-white;
  }
}

.actions-btn {
  gap: 12px !important;
}

.btn-actions-table {
  gap: 5%;
  a {
    min-width: max-content !important;
  }
  span {
    width: 100px;
  }
}
.noGap {
  gap: 0%;
  a {
    margin-left: 10px !important;
  }
}

.uploadFile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.uploadFile + label {
  width: 70%;
  text-align: center;
  i {
    align-items: 10px;
  }
}

.swal2-radio {
  display: block !important;

  label {
    width: 100%;
  }
}

/*TIMELINE*/
.timeLine-body {
  height: 90px;
  max-height: 90px;
  overflow-y: auto;
  padding: 0px 15px 0px 2px !important;
  // margin-top: -13px;

  ul {
    list-style: none;
  }
  li:not(:first-child) {
    margin: 30px 0px 30px 0px;
  }
  li:last-child {
    margin: 30px 0px 0px 0px;
  }
  li:first-child {
    margin-top: 20px;
  }
  li:only-child {
    margin-top: 15px;
  }

  i {
    margin-left: -1.5em;
    margin-right: 0.5em;
    size: 3x;
    color: $danger;
  }

  #time {
    display: block;
    float: right;
  }
}
.timeLine-body::-webkit-scrollbar {
  width: 10px;
  background-color: #c1c1c1;
  border-radius: 2px;
}
.timeLine-body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #8a8a8a;
}

/* INTERNAL NOTES */
.processing-file {
  pointer-events: none;
  opacity: 0.4;
}
.internalNotes-body {
  ul {
    padding: 0px 5px 0px 5px;
  }
  ul li {
    list-style: none;
  }
  li p {
    background-color: $light-white;
    padding: 10px;
    border-radius: 6px;
    width: 100%;
    font-size: 1.25rem;
  }

  .boxHistory {
    height: 400px;
    overflow-y: auto;

    .user {
      font-size: 1rem;
      font-weight: bold;
    }

    a:hover {
      color: $info;
    }
  }
  .boxHistory::-webkit-scrollbar {
    width: 10px;
    background-color: #c1c1c1;
    border-radius: 2px;
  }
  .boxHistory::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #8a8a8a;
  }

  .boxInput {
    width: 100%;
    margin-top: 20px;
    height: 40px;

    #internalNotesDocument {
      display: none;
    }

    input[type='text'] {
      width: 70%;
      height: 40px;

      background-color: $light-white;
      border: 1px;
      border-radius: 4px 0px 0px 4px;
      padding-left: 10px;
      padding-right: 10px;
    }
    input[type='text']:focus {
      outline: none !important;
      border: 1px solid $secondary;
    }
    #labelDocument {
      width: 10%;
      height: 40px;
      background-color: $light-white;
      // display: contents;
      line-height: 40px;
      background-color: #b6b6b6;
      text-align: center;
      cursor: pointer;
      color: $secondary;
    }
    #labelDocument:hover {
      background-color: #a0a0a0;
    }
    button {
      width: 20%;
      height: 40px;
      background-color: $btn-accept-color;
      border: 0px;
      border-radius: 0px 4px 4px 0px;
      color: $menu-active-color;
    }
    button:hover {
      background-color: $btn-accept-hover;
    }
  }
  .img-internal-notes {
    width: 180px;
    height: 180px;
    object-fit: cover;
    border: #4f4f4f outset;
    transition: 0.5s ease;
    backface-visibility: hidden;
    opacity: 1;
  }
  .hover-overlay:hover {
    opacity: 0.6;
  }
}

.preview-docs {
  background-color: aliceblue;
  padding: 3%;
  display: flex;
  gap: 8%;
  align-items: center;
}

/*FULL CALENDAR*/
.FullCalendar {
  /*Full calendar's title*/
  .fc-toolbar-title {
    text-transform: capitalize;
  }

  /*Each event box*/
  .eventBox {
    background-color: $light-yellow;
    border-radius: 4px;
    padding: 10px;
    width: 90%;
    margin: 0px auto;
    height: auto;
    line-height: 40px;
    cursor: pointer;
    overflow: scroll;
    overflow-y: hidden;
    overflow-x: auto;

    h5 {
      font-size: 14px;
      color: $primary;
      text-transform: capitalize;
    }
  }
  .eventBox:hover {
    background-color: $light-yellow-hover;
  }
  .eventBox::-webkit-scrollbar {
    height: 8px;
    background-color: $secondary;
    border-radius: 2px;
  }
  .eventBox::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $btn-accept-border;
  }
}
/*FULL CALENDARs MODAL*/
#quotesModalBox {
  padding: 20px 30px 0px 30px;
  text-align: left;
  max-height: 300px;
  overflow-y: scroll;
  overflow: auto;
  margin: 0px 12px 0px 0px !important;

  .quoteAttribute {
    color: $primary;
    text-align: left !important;
  }
  .quoteAttributeRes {
    float: right;
    text-align: right;
    text-transform: capitalize;
    // width: 250px;
    height: auto;
    max-height: 25px;
    overflow: auto;
  }
  .quoteAttributeRes::-webkit-scrollbar {
    width: 0px;
    background-color: $secondary;
    border-radius: 2px;
  }
  .quoteAttributeRes::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $btn-accept-border;
  }
  h4 {
    margin-bottom: 15px;
    color: $btn-accept-border;
    background-color: $light-yellow;
    width: 95%;
    border-radius: 0px 10px 10px 0px;
    padding: 5px 5px 5px 10px;
    font-size: 18px;

    animation-name: dateAnimation;
    animation-duration: 0.7s;
    -moz-animation-timing-function: inherit;
    -webkit-box-shadow: 5px 5px 16px -5px rgba(0, 0, 0, 0.84);
    box-shadow: 5px 5px 16px -5px rgba(0, 0, 0, 0.84);

    i {
      margin-right: 5px;
    }
  }
  h5 {
    font-size: 18px;
  }
  .docksBox {
    float: right;
  }

  .docksItem {
    background-color: $text-color;
    border-radius: 7px;
    color: $light-white;
    padding: 3px 6px 6px 6px;
    cursor: context-menu;
  }
  .docksItem:not(:last-child) {
    margin: 0px 6px 0px 6px;
  }
  .docksItem:last-child {
    margin: 0px 0px 0px 6px;
  }
  .docksItem:hover {
    background-color: #151515;
  }
}
#quotesModalBox::-webkit-scrollbar {
  width: 10px;
  background-color: $secondary;
  border-radius: 2px;
}
#quotesModalBox::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $btn-accept-border;
}

/*PROVIDERS TABLE*/
.providersTableBox {
  position: relative;
  height: 320px;
  max-height: 320px;
  overflow: auto;
  display: block;

  .header {
    h3 {
      font-size: 24px;
    }
    h6 {
      font-size: 18px;
    }
  }

  #providersTable {
    width: 100%;
    thead {
      background-color: #1c1c1c;
      color: white !important;
    }
    tbody {
      // tr:not(.extraCamp) {
      //   cursor: pointer;
      // }
      .rowError {
        background-color: $danger;
        color: $light-white;
      }
      .rowCorrect:hover:not(.extraCamp) {
        background-color: $light-yellow-hover;
      }
      .rowError:hover:not(.extraCamp) {
        background-color: $danger-hover;
      }

      input[type='checkbox'] {
        width: 100% !important;
      }
      input[type='number'] {
        width: 80%;
      }
      i {
        text-align: center;
        width: 100%;
      }
    }
  }
}
.providersTableBox:not(:first-child) {
  margin-top: 30px;
}
.providersTableBox::-webkit-scrollbar {
  height: 10px;
  background-color: $secondary;
  border-radius: 2px;
}
.providersTableBox::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $btn-accept-border;
}

/*ORDERS TABLE*/
.ordersTableBox {
  position: relative;
  height: auto;
  max-height: 320px;
  overflow: auto;
  display: block;

  .header {
    h3 {
      font-size: 24px;
    }
    h6 {
      font-size: 18px;
    }
  }

  #ordersTable {
    width: 100%;
    th,
    td {
      border: 1px solid white;
    }

    thead {
      background-color: white;
      color: $secondary !important;

      tr {
        border-bottom: 2px solid #e2e2e2 !important;
      }
    }

    tr td {
      border-bottom: 1px solid #e2e2e2 !important;
      background-color: white !important;
    }

    tbody {
      .rowError {
        background-color: $danger;
        color: $light-white;
      }
      .rowCorrect:hover:not(.extraCamp) {
        background-color: $light-yellow-hover;
      }
      .rowError:hover:not(.extraCamp) {
        background-color: $danger-hover;
      }

      input[type='checkbox'] {
        width: 100% !important;
      }
      input[type='number'] {
        width: 80%;
      }
      i {
        text-align: center;
        width: 100%;
      }
    }
  }
}
.ordersTableBox:not(:first-child) {
  margin-top: 30px;
}
.ordersTableBox::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  background-color: $secondary;
  border-radius: 2px;
}
.ordersTableBox::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $btn-accept-border;
}

.providerScheduleModalTable {
  padding: 20px !important;

  .boxItem {
    text-align: left !important;
  }

  .providersTableBox {
    height: 220px !important;
    max-height: 220px !important;
  }
}

.boxQuotesProvider {
  h4 {
    font-size: 18px;
    color: $primary;
  }
  h5 {
    font-size: 24px;
    color: $primary;
  }
  .resultData {
    color: $secondary;
  }
}

.scanCodeCam {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 30vh;
  overflow: hidden;
}
// .scanCodeCam::before {
//   content: url('../Assets/Images/scanIcon.png');
//   position: absolute;
//   top: 20%;
//   left: 42%;
//   transform: scale(0.4);
// }
.gallery {
  display: flex;
  overflow-x: scroll;

  .gallery-item i {
    cursor: pointer;
    color: black;
  }
}
.gallery-item img {
  width: 100%;
  height: 100%;
}

/*ORDERS DETAILS*/
.ordersTableDetails {
  max-height: 200px;
  width: 770px;
  min-width: 400px;
  max-width: 770px;
  overflow-y: auto;
  overflow-x: scroll;
}

@keyframes dateAnimation {
  0% {
    margin-left: -300px;
    visibility: hidden;
  }
  100% {
    margin-left: 0px;
    visibility: visible;
  }
}

.one-centered {
  display: flex;
  justify-content: center;
  padding-top: 10%;
  padding-bottom: 10%;
}

.text-one {
  padding: 10%;
  font-size: 20px;
}

//HIDE LICENSE MESSAGE FROM FULL CALENDAR
.fc-license-message {
  display: none;
}

.docksItem {
  background-color: $text-color;
  border-radius: 7px;
  color: $light-white;
  padding: 3px 6px 6px 6px;
  margin-right: 2%;
  cursor: context-menu;
}

.actions-buttons-scan {
  display: flex !important;
  justify-content: flex-end !important;
  gap: 2% !important;
}

.card-stretch {
  display: grid;
}

.actions_orders_buttons {
  display: flex;
  justify-content: space-between;
  gap: 5%;
  padding-bottom: 3%;
}

.action_orders_one_button {
  display: flex;
  justify-content: flex-end;
  gap: 5%;
  padding-bottom: 3%;
}

//
.inptu-manual-content {
  display: flex;
  align-content: center;
  justify-content: center;
}
// Scanner
.scannerComp canvas {
  display: none;
}
#scannerComp canvas,
video {
  width: 400px;
  height: 300px;
  // position: absolute;
  top: 0;
  left: 0;
}

.hidePagination {
  .rdt_Pagination {
    display: none;
  }
}

//mainStats
.mainStatsBox {
  h3 {
    color: #707070;
    font-weight: bold;
    font-size: 40px;
    // text-align: center;
  }
  h5 {
    color: #707070;
    font-size: 15px;
    // text-align: center;
  }
}
//end mainStats

//salesComparisonBox
.salesComparisonBox {
  h5 {
    font-weight: bold;
  }
}
//end salesComparisonBox

//SalesComparisonCheckItemsBox
.SalesComparisonCheckItemsBox {
  h5 {
    color: #707070;
    font-size: 16px;
    font-weight: none !important;
    text-transform: none;
  }
  h6 {
    color: #707070;
    font-weight: none !important;
    font-size: 14px;
  }
  .titleContainer {
    display: flex;
    align-items: baseline;

    input[type='checkbox'] {
      margin-right: 5px;
    }

    .boxColor {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }
  .bodyContainer {
    margin-left: 40px;
  }
}
//end SalesComparisonCheckItemsBox

//start buttonsFixSpaces
.buttonsFixSpaces {
  .btn-actions-table {
    grid-gap: 0;
    gap: 0;
  }

  .btn:first-child {
    margin-right: 5px;
  }
}
//end buttonsFixSpaces

//Start displacementItemMain
.displacementItemMain {
  padding: 10px;
  h6 {
    font-weight: bold;
    text-transform: uppercase;
  }
}
//End displacementItemMain

//Start displacementSalesComparativeMain
.displacementSalesComparativeMain {
  .graphicsColors {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
//End displacementSalesComparativeMain

//Start DownloadReportsMain
.DownloadReportsMain {
  // width: 447px;
  padding: 10px;

  .swal2-actions {
    display: flex;
    flex-direction: row-reverse;
  }

  h5 {
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .datesBox {
    .col-12 {
      display: flex;
      justify-content: flex-start;
    }
    input,
    label {
      font-size: 15px;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    input {
      border: 0.5px solid grey;
    }
    label {
      display: flex;
    }
  }

  .dataCheckBox {
    display: flex;
    justify-content: left;
    input {
      margin-right: 5px;
      cursor: pointer;
    }
    label {
      cursor: pointer;
    }
    .col-6 {
      text-align: start;
    }
  }

  .buttonsBox {
    display: flex;
    justify-content: space-between;
    span {
      width: 100px;
    }
    .cancel {
      background-color: $light-white !important;
      color: $btn-accept-hover !important;
    }
    .cancel:hover {
      background-color: #c1c1c1 !important;
    }
  }
}
//End DownloadReportsMain

//Start commodityReceiptLinesMain
.commodityReceiptLinesMain {
  .linesInputs {
    display: flex;
    justify-content: flex-start;
    // width: 80%;

    label {
      margin-right: 10px;
    }
    input {
      margin-right: 10px;
    }
    .iconDelete {
      cursor: pointer;
      margin-left: 10px;
    }
    margin-top: 20px;
  }
  .StandarModalConfirmButtonLogOut {
    width: 50% !important;
  }
}

//End commodityReceiptLinesMain

//Start status box
.statusBox {
  border-radius: 6px;
  color: $light-white;
  text-align: center;
  // max-width: 160px;
  min-width: 100px;
  width: auto;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    align-items: center;
  }
}
.statusBox-checked {
  background-color: #6dbe85;
  color: $light-white;
}
.statusBox-pending {
  background-color: #0b5fb2;
  color: $light-white;
}
.statusBox-pending-gray {
  background-color: #707070;
  color: $light-white;
}

//End status box

//Start ticketsMain
.ticketsMain {
  display: flex;
  justify-content: center;
  align-items: center;

  .statusBox {
    width: 40%;
  }

  p {
    display: flex;
    // .statusBox{
    //   margin-left: 10px;
    // }
  }
}

//End ticketsMain

//Start modal
.modal-header {
  background-color: $text-color;
  line-height: 20px !important;
  height: 57px;
  max-height: 57px;
  size: 13px;
  color: white !important;
  font-size: 20px !important;
  letter-spacing: 1px !important;
  text-align: left !important;
  margin-right: -0.1%;
}

.modal-close-button {
  width: unset !important;
  height: unset !important;
}

//End modal

//Start Historial
.historialMain {
  .card-body {
    max-height: 210px;
    overflow-y: scroll;
  }

  .card-body::-webkit-scrollbar {
    width: 7px;
    background-color: white;
    border-radius: 2px;
  }
  .card-body::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $btn-accept-border;
  }
}
//End historial

//Start ticketsButtons
.ticketsButtons {
  .boxButtons {
    label {
      margin-right: 10px;
    }
    display: flex;
    align-items: center;
    justify-content: end;
  }
}
//End ticketsButtons

.table-designation thead th {
  border-top: none;
}

.wr-table::-webkit-scrollbar {
  width: 7px;
  background-color: white;
  border-radius: 2px;
}

.wr-table::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $btn-accept-border;
}

.card-header {
  a,
  button {
    background-color: $light-white;
    color: $secondary;
  }
  a:hover,
  button:hover {
    background-color: $light-white-hover;
    color: $secondary;
  }
}

//Drinks votes
.drink-vote-box {
  background-color: #eb412f;
  display: flex;
  justify-content: center;
  // height: 100vh;
  h1 {
    font-family: 'Alquimia_Text';
    font-size: 80px;
    color: $alquimia-red-light;
  }
  h2 {
    font-family: 'Alquimia_Title';
    text-transform: uppercase;
    color: $light-white;
    font-size: 20px;
    letter-spacing: 2px;
  }
  h3 {
    font-family: 'Alquimia_Title';
    text-transform: uppercase;
    color: $alquimia-red-light;
    letter-spacing: 2px;
  }
  p {
    font-family: 'Alquimia_Title';
    text-transform: uppercase;
    color: $alquimia-red-light;
    font-size: 20px;
    letter-spacing: 2px;
  }
  .message-success-two {
    // height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
  }
  .message-success {
    font-family: 'Alquimia_Text';
    font-size: 60px;
    color: $alquimiaWhite;
    font-weight: bold;
  }

  .card {
    box-shadow: none;

    .card-header {
      display: none;
    }
    .card-body {
      background-color: #eb412f;
    }
    .alquimia-eye {
      display: flex;
      justify-content: center;
      img {
        width: 80px;
      }
    }
    .alquimia-star {
      display: flex;
      justify-content: center;
      img {
        width: 50px;
      }
    }
  }
  .drink-vote-container {
    .branch-image-box {
      display: flex;
      justify-content: center;
    }
    .branch-image,
    .branch-image-two {
      display: flex;
      justify-content: center;
      align-items: center;

      // width: 250px;
      // height: 250px;
      border-radius: 140px 140px 6px 6px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      background-color: $alquimia-red-light;
      padding: 30px;
      margin-left: 15px;
      margin-right: 15px;

      img {
        // width: 170px;
        // height: 170px;
        // background-color: $alquimia-red-light;
      }
    }
    .branch-image-two {
      width: 200px;
      height: 200px;
      img {
        width: 110px;
        // height: 110px;
        padding-top: 12px;
        background-color: $alquimia-red-light;
      }
    }
    .drink-details {
      text-align: center;
      h1 {
        font-size: 45px;
        letter-spacing: 3px;
        line-height: 0.2 !important;
      }
    }
    .drink-calification {
      h2 {
        color: $alquimia-red-light;
      }
      text-align: center;
    }
    .drink-button {
      text-align: center;
      button {
        width: 80%;
        font-size: 20px;
        background-color: $alquimiaRed;
        color: $alquimiaWhite;
      }
      button:hover {
        background-color: $alquimiaPurple;
      }
    }
    .btn-accept {
      width: 50%;
    }
  }

  .image-boxe {
    display: flex;
    justify-content: space-between;
    img {
      margin: 0px 10px 0px 10px;
      filter: invert(50%) sepia(0%) saturate(714%) hue-rotate(304deg) brightness(500%) contrast(100%);
    }
  }
}

.swal2-success-ring,
div:where(.swal2-icon).swal2-warning {
  border: 0.25em solid $alquimia-red-light !important;
}
.swal2-icon.swal2-success [class^='swal2-success-line'] {
  background-color: $alquimia-red-light;
}
div:where(.swal2-icon).swal2-info {
  border-color: $alquimia-red-light !important;
}
.swal2-icon.swal2-info,
.swal2-icon.swal2-warning {
  color: $alquimia-red-light;
}

.footer-client {
  display: none;
}

// //Vote item
.vote-item {
  padding: 0px;
  h2 {
    font-size: 1.3em;
    margin: 0px;
    padding: 0px;
    text-transform: uppercase;
  }
  img {
    // width: 10em;
    width: 7.8em;
  }
}

//Alquimia table
.alquimia-table-box {
  height: 100vh;
  overflow-x: auto;
  background-color: $alquimia-table;
  padding: 0px 10px 0px 10px;
  .alquimia-table-header {
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 60px 0px 0px 0px;
    img {
      width: 98%;
    }
  }
  .alquimia-table {
    width: 100%;
    color: $light-white;
    font-family: 'Alquimia_Title';
    text-align: center;
    letter-spacing: 2px;
    tr td,
    tr th {
      // padding: 10px;
      padding: 0px;
      justify-content: center;
      align-items: center;
      font-size: 1em;
      width: auto;
      max-width: 185px;
    }

    tr td {
      font-family: 'Alquimia_Title';
      letter-spacing: 2px;
      div h2 {
        margin-bottom: 5px;
      }
    }
    .shadow span {
      text-shadow: 1px 1px black;
    }

    thead tr th {
      div {
        width: auto;
        border-radius: 80px;
        // border-style: solid;
        border-width: 2px;
        border-color: $light-white;
        padding: 10px 3px 3px 3px;
        background-color: $alquimia-table-title;
      }
      h2 {
        border-radius: 30px;
        // margin: 10px 5px 10px 10px;
        text-transform: uppercase;
        font-family: 'Alquimia_Title';
        font-size: 1.3em;
        letter-spacing: 2px;
      }
    }

    tr th:first-child div{
      width: 70%;
      margin-left: 15%;
    }

    tr th:nth-child(2) div {
      width: 62%;
      margin-left: 19%;
    }

    tr th:nth-child(3) div {
      width: 90%;
      margin-left: 5%;
    }

    tr th:nth-child(4) div {
      width: 80%;
      margin-left: 10%;
    }

    tr td:nth-child(3),
    tr td:nth-child(4) {
      font-size: 2em;
    }

    tbody .row-space {
      padding: 4px 0px 4px 0px;
      // background-color: red;
    }

    tbody .row-space:nth-child(1){
      padding: 0px 0px 18px 0px;
    }
  }
  .alquimia-table thead th:nth-child(3) div{
    padding: 0px;
  }
  .alquimia-table-pagination {
    width: 100%;

    ul {
      width: 100%;
      display: flex;
      justify-content: center;
      list-style: none;
      color: $light-white;
      cursor: pointer;
      li,
      li a {
        width: auto;
        background-color: #49003a;
        color: $light-white;
        font-size: 20px;
        border-radius: 6px;
      }
      li:hover,
      li a:hover {
        a {
          background-color: #310128;
        }
        background-color: #310128;
      }
      .selected,
      .selected a {
        background-color: $alquimia-pink;
        color: black;
      }
      li {
        margin: 0px 10px 0px 10px;
      }
      li a {
        padding: 0px 12px 0px 12px;
      }
    }
  }
}

.alquimia-table-box .alquimia-table thead tr th:nth-child(3) h2 {
  margin: 0px;
}

//DASHBOARD HOME
.dashboard {
  font-family: 'Alquimia_Title';
  letter-spacing: 2px;
  .number-identify {
    color: $light-white;
    font-size: 20px;
    padding: 0px;
    margin: 0px;
    p {
      padding: 0px;
      margin: 0px;
    }
  }
  .instructions-img {
    width: 100%;
  }
}

/* The switch - the box around the slider */
.switchVotes {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  
}

@media (max-width: 440px) {
  .drink-vote-box .drink-vote-container .image-box-two .branch-image-two {
    width: 150px;
    height: 150px;
    margin-left: 10px;
    margin-right: 10px;
    img {
      height: auto;
    }
  }
}

@media (max-width: 767px) {
  //Start ticketsButtons
  .ticketsButtons {
    .boxButtons {
      justify-content: start;
    }
  }
  .votes-finished{
    font-size: 50px !important;
  }
}

@media (max-width: 780px) {
  .alquimia-table-box {
    .alquimia-table {
      thead tr th div {
        padding: 10px;
      }
      tr th {
        font-size: 0.8em !important;
      }
      tr th h2 {
        font-size: 0.8em !important;
        margin: 0px !important;
      }
      tr td:nth-child(3),
      tr td:nth-child(4) {
        font-size: 1.2em !important;
      }
      tr th div {
        width: 100% !important;
        margin: 0px !important;
      }
      tbody .row-space {
        padding: 15px 0px 15px 0px;
      }
    }
  }

  //Vote item
  .vote-item {
    h2 {
      font-size: 0.8em;
    }
    img {
      width: 3.5em;
    }
  }
}

@media (max-width: 991px) {
  // start commodityReceiptLinesMain
  .commodityReceiptLinesMain {
    .linesInputs {
      .iconDelete {
        margin-top: 20px;
      }
    }
  }
  // start commodityReceiptLinesMain
  .statusBox {
    min-width: 0px;
  }
}

@media (max-width: 1999px) {
  .ticketsMain {
    .actionButtonsGroup {
      margin-top: 10px;
      display: block;
    }
  }
}
