.font-red-color {
  color: #d10000;
}
.bg-red-color {
  background-color: #3a0014;
}
.bg-rose-color {
  background-color: #e9cbc3;
}

.fs-30 {
  font-size: 30px;
}
.fs-26 {
  font-size: 26px;
}
.fs-22 {
  font-size: 22px;
}

.title-star {
  width: 70px;
  height: 70px;
  margin: 0 auto;
}

.instructions-content {
}

.line-height-half {
  line-height: 1;
}

.number-container {
  background-color: #d10000;
  margin: 0 auto 20px auto;

  font-size: 22px;
  text-align: center;
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.bg-area-image {
  height: 70vh;
  background-size: contain;

  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../Assets/Images/dashboard/instructions-2.png');

  // @media (max-width: 900px) {
  //   height: 42vh;
  // }

  @media (max-width: 600px) {
    background-image: url('../Assets/Images/dashboard/instructions-2-mobile.png');
  }
}

.brands-bars div img {
  display: block;
  margin: 0 auto;
}

.order-element-1 {
  order: 1;
  @media (max-width: 600px) {
    order: 2;
  }
}
.order-element-2 {
  order: 2;
  @media (max-width: 600px) {
    order: 1;
  }
}
