.full-box {
  // background-color: $login-box-inputs-color;
  padding: 2%;
  width: 500px;

  button {
    background-color: $danger;
    color: $light-white;
  }
  a {
    color: $danger;
  }

  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::before {
    background-color: $login-text-color !important;
  }
}

body:has(.full-container-register){
  background-color: $alquimiaWhite;
}

.full-container-register {
  display: flex;
  flex-direction: column;
  background-color: $login-bg-color;
  background: url('../Assets/Images/login-bg-test.png');
  background-size: cover;
  background-position-x: 50%;
  background-position-y: 20%;
  background-repeat: no-repeat;

  width: 100%;
  height: 100vh;

  align-items: center;
  justify-content: center;

  .row:has(.image-box){
    margin: 0px;
  }

  .image-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .card {
    background-color: $alquimiaWhite;
    border: 0px;
    box-shadow: none;
    // border-radius: 0px !important;
    label b,
    label {
      color: $alquimia-red-light;
    }
    .card-header {
      display: none;
    }
    .card-body {
      // padding: 6px;
      // height: 100vh;
      background-color: $alquimia-red-high;
      border: 0px;
      input {
        background-color: $light-white;
      }
    }
    span {
      color: $alquimia-red-light;
      font-weight: bold;
    }
  }

  form {
    padding: 50px;
  }

  .form-control.is-invalid {
    background-image: none !important;
  }
}

.input-range__slider {
  background: $alquimia-red-light !important;
  border: 1px solid $alquimia-red-light !important;
}
.input-range__track--active {
  background-color: $alquimia-red-light !important;
}


.full-container {
  display: flex;
  flex-direction: column;
  background-color: $login-bg-color;
  background: url('../Assets/Images/login-bg-test.png');
  background-size: cover;

  // -webkit-appearance: none;
  background-position-x: 50%;
  background-position-y: 20%;
  // background-size: cover;
  background-repeat: no-repeat;

  align-items: center;
  width: 100%;
  height: 100vh;
  justify-content: center;
  span {
    font-size: 25px;
  }
  
}

.img-logo-full {
  width: 400px;
  height: 90px;
  // filter: invert(1) !important;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(151deg) brightness(98%)
    contrast(402%);
}

.actions-login {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  a {
    color: $login-text-color;
    text-decoration: underline;
  }
  label {
    color: $login-text-color;
    font-weight: lighter !important;
  }
}

.login-btn {
  padding-top: 8%;
  button {
    display: block;
    margin: auto;
    width: 80%;
    font-weight: bold;
    font-size: 16px;
  }
}

.input-icons-login {
  margin-bottom: 3%;
  width: 100%;
  input {
    background-repeat: no-repeat;
    background-position: 10px;
    display: flex;
    padding-left: 10%;
  }
  // #user {
  //   background-image: url('./../Assets/icons/user.svg');
  //   background-size: 16px 16px;
  // }
  // #pass {
  //   background-image: url('./../Assets/icons/lock.svg');
  //   background-size: 16px 16px;
  // }
}
.input-icons-login #user:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  background-image: url('./../Assets/icons/user.svg') !important;
  background-size: 16px 16px !important;
}

.icon-inside i {
  position: absolute;
  top: 45px;
  left: 92%;
  cursor: pointer;
}

.input-icons-login #pass:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
.iconInside {
  display: flex;
  .fa-eye {
    background-color: #fff;
    line-height: 36px;
    border: 1px solid #ced4da;
    border-radius: 0px 6px 6px 0px;
    padding-right: 12px;
    border-left: 0px;
    cursor: pointer;
  }
  .fa-user,
  .fa-lock {
    background-color: #fff;
    line-height: 35px;
    border: 1px solid #ced4da;
    border-radius: 6px 0px 0px 6px;
    padding-left: 12px;
    border-right: 0px;
    // cursor: pointer;
    color: #d2d2d2;
  }

  #pass,
  #pass1,
  #pass2 {
    border: 1px solid #ced4da;
    border-radius: 0px;
    border-right: 0px;
    border-left: 0px;
    padding-left: 4%;
  }
  #user {
    border: 1px solid #ced4da;
    border-radius: 0px 6px 6px 0px;
    border-left: 0px;
    padding-left: 4%;
  }
}

.btn-login {
  background-color: $alquimia-red-light !important;
  border-radius: 3px !important;
}
.btn-login:hover {
  color: $light-white-hover;
}

@media (max-width: 320px){
  .icon-inside i {
    left: 80% !important;
  }
}

@media (max-width: 440px) {
  .container-loading {
    .img-logo-full {
      width: 200px;
      height: 60px;
    }
    .img-loading {
      width: 200px;
      height: 60px;
    }
  }
}

@media (max-width: 775px) {
  .full-container-register {
    .card {
      .card-header {
        display: flex;
      }
    }
  }
}

@media (max-width: 998px) {
  .icon-inside i {
    left: 90%;
  }
  .full-container-register {
    width: auto;
    height: auto;
    padding: 0px;
    margin: 0px;
    form {
      padding: 0px;
      margin: 0px;
    }
    .card {
      margin: 0px;
    }
    align-items: normal;
    justify-content: baseline;
  }
  .img-logo-full {
    width: auto;
    height: 80px;
  }
}

@media (max-width: 992px) {
  .full-container,
  .full-container-register {
    background-image: none !important;
    background-color: $login-bg-color !important;
  }
}

@media (max-width: 420px) {
  .img-logo-full {
    // width: 270px !important;
  }
  .input-icons-login {
    input {
      padding-left: 14%;
    }
  }
  .actions-login {
    display: grid;
    justify-content: center;
  }
}
