// Paleta de colores


$alquimiaRed: #e50000;
$alquimiaPurple: #340414;
//--- NEWS
$alquimiaGreen: #182114;
$alquimiaWhite: #e8e1d1;

$gray-dark: $alquimiaPurple; // #000 sidebar, headings, gray dark
$gray-dark-hover: #555454; // sidebar, headings, gray dark
$gray-dark-border: rgb(239, 239, 239); // sidebar, headings, gray dark

$light-white: rgb(239, 239, 239); //content area background, light
$light-white-hover: #cfcfcf;

$secondary: #555454; // cuerpos de texto, secondary
$primary: $alquimiaPurple; //#000 opción del menú lateral activa y highlights, primary

$info: #3c5ba3; //Info
$info-hover: #2e457a; //Info
$info-border: #1e3670; //Info

$danger: $alquimiaRed; // #c3353f Danger
$danger-hover: $alquimiaPurple; // #912930 Danger
$danger-border: #bd2c35; // Danger

$btn-accept-color: $alquimiaRed; //#490005
$btn-accept-hover:  $alquimiaPurple; //#350004
$btn-accept-border: #413e3e;

$light-yellow: #ffd281;
$light-yellow-hover: #ffc760;

$btn-accept-txtcolor: $light-white;
$btn-info-txtcolor: $light-white;
$btn-danger-txtcolor: $light-white;

$text-color: $alquimiaPurple; //#1c1c1c
$checkbox-colorBG: $text-color;

$menu-hover-colorBG: rgba(131, 131, 131, 0.555);
$menu-active-txtcolor: #fff;
$menu-hover-txtcolor: #fff;

$sidebar-bg-color: #efefef;
$menu-active-color: #d5d5d5;
$submenu-active-color: $text-color;
$layout-table-content-color: #707070;
$badge-color: $menu-active-color;
$badge-text-color: $text-color;

$login-bg-color: $alquimiaRed; //#ff2d1d
$login-box-inputs-color: #efefef;
$login-text-color: $primary;

$alquimia-red-high: $alquimiaRed; //#ff2d1d
$alquimia-red-light: $alquimiaPurple; //#490005
$alquimia-pink: #fbddff;

$card-header: $alquimia-red-light;
$card-body: white;
$alquimia-table-title: #d003fe;
$alquimia-table: $alquimiaPurple; //#49003a

// DRINKS VOTES COMPONENT COLORS
$votes-background: $alquimiaGreen;
$votes-title: #d10000;
// Font
@font-face {
  font-family: 'Moderat';
  src: url('./../Assets/Fonts/Moderat/Moderat-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moderat';
  src: url('./../Assets/Fonts/Moderat/Moderat-Bold.ttf');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Alquimia_Title';
  src: url('./../Assets/Fonts/Alquimia/FormulaCondensed/FormulaCondensed-Regular.otf');
}

@font-face {
  font-family: 'Alquimia_Title_Bold';
  src: url('./../Assets/Fonts/Alquimia/FormulaCondensed/FormulaCondensed-Bold.otf');
}

@font-face {
  font-family: 'Alquimia_Text';
  src: url('./../Assets/Fonts/Alquimia/TTChocolate/TTChocolatesTrialDemiBold.otf');
}

$font: 'Alquimia_Text';
