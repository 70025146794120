@import './Settings.scss';

#recordsReport{
    .header {
        text-align: center;
        padding: 40px 0px;
    }
    .section {
        height: 60vh;
    }
    .row{
        background-color: $alquimiaWhite;
        .description{
            margin: auto;
            text-align: center;
            h2{
                font-family: 'Alquimia_Title_Bold';
                text-transform: uppercase;
                color: #EA9912;
                letter-spacing: 2px;
                font-size: 50px;
            }
            p {
                width: 70%;
                margin-left: 15%;
                font-family: 'Alquimia_Text';
            }
        }
        .graphic{
            position: relative;
            height: 100%;

            .logo-small{
                width: 60px;
                height: 30px;
                position: absolute;
                z-index: 2;
                bottom: 0px;
                right: 30px;
            }
        }

        .notesBox{
            padding: 60px 0px;
            ul{
                width: 40%;
                margin-left: 30%;
                li{
                    text-align: left;
                }
            }
        }
    }
}

@media(max-width: 991px){
    #recordsReport{
        .row {
            .notesBox ul{
                width: 90%;
                margin-left: 5%;
            }
        }
    }
}